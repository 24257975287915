import { HTMLAttributes, SVGProps } from 'react';

// : { [key: string]: string }
export const IconColors = {
    instagram: { color: '#8a3ab9', type: 'hex' },
    spotify: { color: '#1DB954', type: 'hex' },
    youtube: { color: '#ff4136', type: 'hex' },
    tiktok: { color: 'black', type: 'tailwind' },
    basecamp: { color: '#1d2d35', type: 'hex' },
    spp: { color: 'blue-800', type: 'tailwind' },
};

function getSVGColor({ color, type }: { color: string; type: string }) {
    return type === 'hex' ? color : `fill-${color}`;
}

export interface IconProps extends SVGProps<SVGSVGElement> {
    colored?: boolean;
}

export function SpotifyIcon(props: IconProps) {
    const { colored, ...svgProps } = props;
    if (colored)
        return (
            <SpotifySVG
                fill={getSVGColor({ ...IconColors.spotify })}
                {...svgProps}
            />
        );
    return <SpotifySVG {...svgProps} />;
}

export function YouTubeIcon(props: IconProps) {
    const { colored, ...svgProps } = props;
    if (colored)
        return (
            <YouTubeSVG
                fill={getSVGColor({ ...IconColors.youtube })}
                {...svgProps}
            />
        );
    return <YouTubeSVG {...svgProps} />;
}

export function InstagramIcon(props: IconProps) {
    const { colored, ...svgProps } = props;
    if (colored) return <InstagramSVG {...svgProps} />;
    return <InstagramSVG fill="currentColor" {...svgProps} />;
}

export function TikTokIcon(props: IconProps) {
    const { colored, ...svgProps } = props;
    if (colored)
        return (
            <TikTokSVG
                fill={getSVGColor({ ...IconColors.tiktok })}
                {...svgProps}
            />
        );
    return <TikTokSVG {...svgProps} />;
}

export function BaseCampIcon(props: IconProps) {
    const { colored, ...svgProps } = props;
    if (colored)
        return (
            <BasecampSVG
                fill={getSVGColor({ ...IconColors.basecamp })}
                {...svgProps}
            />
        );
    return <BasecampSVG {...svgProps} />;
}

export function SPPIcon(props: IconProps) {
    const { colored, ...svgProps } = props;
    if (colored)
        return (
            <SPPSvg fill={getSVGColor({ ...IconColors.spp })} {...svgProps} />
        );
    return <SPPSvg {...svgProps} />;
}

export function SpotifySVG(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            role="img"
            preserveAspectRatio="xMinYMax meet"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <title className="sr-only">Spotify</title>
            <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z" />
        </svg>
    );
}

export function YouTubeSVG(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            role="img"
            // preserveAspectRatio="xMinYMax meet"
            viewBox="0 0 24 17"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            {...props}
        >
            <title className="sr-only">YouTube</title>
            <path d="M23.498 2.641A3.02 3.02 0 0021.376.505C19.505 0 12 0 12 0S4.495 0 2.623.505A3.02 3.02 0 00.502 2.641C0 4.525 0 8.455 0 8.455s0 3.93.502 5.814a3.02 3.02 0 002.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.018 3.018 0 002.122-2.136C24 12.385 24 8.455 24 8.455s0-3.93-.502-5.814zM9.545 12.023V4.887l6.273 3.568-6.273 3.568z" />
        </svg>
    );
}

export function InstagramSVG(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            role="img"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            // fill = 'url(#rg)'
            // fill = 'currentColor'
            fill="url(#rg)"
            {...props}
        >
            {props.fill ?? (
                <radialGradient id="rg" r="150%" cx="30%" cy="107%">
                    <stop stopColor="#fdf497" offset="0" />
                    <stop stopColor="#fdf497" offset="0.05" />
                    <stop stopColor="#fd5949" offset="0.45" />
                    <stop stopColor="#d6249f" offset="0.6" />
                    <stop stopColor="#285AEB" offset="0.9" />
                </radialGradient>
            )}
            <title className="sr-only">Instagram</title>
            <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
        </svg>
    );
}

export function TikTokSVG(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            role="img"
            preserveAspectRatio="xMinYMax meet"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z" />
        </svg>
    );
}

export function BasecampSVG(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            role="img"
            className="w-9/12"
            viewBox="0 0 37 33"
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            fill="currentColor"
            {...props}
        >
            <path
                d="M19.414 32.08c-6.188 0-11.625-2.385-15.72-6.896a1.707 1.707 0 0 1-.336-1.741c1.076-2.897 3.98-9.637 7.704-9.659h.02c1.87 0 3.27 1.481 4.395 2.672.337.356.852.902 1.2 1.167.868-.857 2.575-3.706 3.902-6.563a1.707 1.707 0 0 1 3.095 1.436c-4.024 8.67-6.168 8.67-6.872 8.67-1.569 0-2.705-1.203-3.805-2.366-.492-.521-1.515-1.604-1.915-1.604-.87.145-2.775 3.151-4.138 6.45 3.364 3.333 7.554 5.022 12.47 5.022 6.745 0 11.79-1.836 13.964-5.056-.75-8.571-5.47-20.199-13.964-20.199-7.19 0-12.606 5.008-16.099 14.885a1.707 1.707 0 0 1-3.217-1.137C4.124 5.774 10.623 0 19.414 0c11.482 0 16.772 14.672 17.411 23.92a1.71 1.71 0 0 1-.224.969c-2.672 4.637-8.775 7.191-17.187 7.191Z"
                fill={props.fill ? '#1d2d35' : 'currentColor'}
                fillRule="nonzero"
            />
        </svg>
    );
}

export function SPPSvg(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            role="img"
            className="w-9/12"
            viewBox="0 0 61 44"
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            {...props}
        >
            <path
                d="M34 39.5V19.4c0-1.3 1.1-2.4 2.4-2.4h21.3c2.1 0 3.2 2.6 1.6 4.1L38 41.2c-1.5 1.4-4 .4-4-1.7Z"
                fill={props.fill ? '#09588e' : 'currentColor'}
                fillRule="nonzero"
            />
            <path
                d="M58 10H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h56c1.1 0 2 .9 2 2v6c0 1.1-.9 2-2 2Zm-36 7H2c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2Zm0 17H2c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2Z"
                fill={props.fill ? '#28ce6b' : 'currentColor'}
                fillRule="nonzero"
            />
        </svg>
    );
}
