import { BaseCampIcon, SPPIcon } from '@/components/icons';
import Section from '@/components/Section';
import ServiceCard from '@/components/ServiceCard';
import SubNavigation from '@/components/SubNavigation';
import classNames from '@/util/classname';
import type { NextPage } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import type { NavigationProps } from '@/components/Navigation';
import { ShoppingCartIcon } from '@heroicons/react/20/solid';

const navigation: NavigationProps[] = [
    {
        text: 'Basecamp',
        href: 'https://3.basecamp.com/5270946',
        Icon: BaseCampIcon,
        bgStyles: 'bg-[#1d2d35] text-white',
        iconBg: 'bg-[#ffee00] shadow-yellow-900/60',
    },
    {
        text: 'SPP',
        href: 'https://virramarketing.spp.io/',
        Icon: SPPIcon,
        bgStyles: 'bg-blue-800 text-white',
        iconBg: 'bg-white shadow-blue-900/60',
    },
    {
        text: 'Virra Services',
        href: '/services/edit',
        Icon: ShoppingCartIcon,
        bgStyles: 'bg-gray-900 text-white',
        iconBg: 'bg-white shadow-purple-900/60',
    },
];

export const ToolsSection = () => {
    return (
        <Section>
            <h2 className="pt-4 pb-2 text-xl font-extrabold tracking-tight text-gray-900">
                Tools
            </h2>
            <div className="grid grid-cols-2 gap-4">
                {navigation.map((navItem) => (
                    <ServiceCard
                        key={navItem.href}
                        href={navItem.href}
                        bgStyles={classNames(
                            navItem.bgStyles ? navItem.bgStyles : '',
                            'aspect-[1/1] rounded-lg group'
                        )}
                        text={navItem.text}
                        icon={{
                            Icon: navItem.Icon,
                            bg: navItem.iconBg ? navItem.iconBg : '',
                        }}
                        newTab={true}
                    />
                ))}
            </div>
        </Section>
    );
};

const Tools = () => {
    return (
        <div>
            <Head>
                <title>Create Next App</title>
                <meta
                    name="description"
                    content="Generated by create next app"
                />
                <link rel="icon" href="/favicon.ico" />
            </Head>

            <main>
                <SubNavigation navigation={navigation} />
                <ToolsSection />
            </main>
        </div>
    );
};

export default Tools;
