import classNames from '@/util/classname';
import useHover from '@/util/useHover';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { IconProps } from './icons';
import type { HTMLAttributes, SVGProps } from 'react';

type NavItemProps = {
    text: string;
    href: string;
    Icon?: (props: IconProps) => JSX.Element;
};

function NavigationButton({ navItem }: { navItem: NavItemProps }) {
    const [hoverRef, isHovered] = useHover();
    const router = useRouter();
    // console.log(navItem.href);
    // console.log(router.pathname);
    return (
        <div ref={hoverRef as React.RefObject<HTMLDivElement>}>
            <Link href={navItem.href}>
                <a
                    title={navItem.text}
                    className={classNames(
                        navItem.href === router.pathname
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                        'flex w-full items-center justify-center rounded-lg py-3 sm:px-12'
                    )}
                >
                    {navItem.Icon ? (
                        <navItem.Icon
                            className="h-6 w-6"
                            colored={isHovered ? true : false}
                        />
                    ) : (
                        <span>{navItem.text}</span>
                    )}
                </a>
            </Link>
            <div className="relative">
                {isHovered ? (
                    <div className="absolute left-1/2 mx-auto mt-1 -translate-x-1/2 rounded-md bg-gray-700 p-2 text-xs text-white shadow-xl">
                        <span>{navItem.text}</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default function SubNavigation({
    navigation,
}: {
    navigation: NavItemProps[];
}) {
    return (
        <nav className="pb-1">
            <ul
                className={`grid grid-cols-${navigation.length} m-auto max-w-prose gap-4 px-4`}
            >
                {navigation.map((navItem) => (
                    <li key={navItem.href}>
                        <NavigationButton navItem={navItem} />
                    </li>
                ))}
            </ul>
        </nav>
    );
}
