import classNames from '@/util/classname';
// TODO: Fix reference
import { LinkIcon as ExternalLinkIcon } from '@heroicons/react/20/solid';
import { IconProps } from './icons';
import LinkWrapper from './LinkWrapper';

export default function ServiceCard({
    href,
    bgStyles,
    text,
    linkStyles,
    icon,
    newTab,
}: {
    href: string;
    bgStyles: string;
    text: string;
    linkStyles?: string;
    icon?: {
        Icon: (props: IconProps) => JSX.Element;
        bg: string;
    };
    newTab?: boolean;
}) {
    const external = href.charAt(0) !== '/';

    return (
        <LinkWrapper href={href} className={linkStyles} newTab={newTab}>
            <div
                className={classNames(
                    bgStyles,
                    'rounded-lg bg-cover bg-center'
                )}
            >
                <div
                    className={classNames(
                        'relative rounded-lg',
                        icon?.Icon ? '' : 'bg-gradient-to-b from-black/30'
                    )}
                >
                    <h3 className="p-4 text-2xl font-bold">{text}</h3>
                    {external && (
                        <span className="absolute -right-2 -top-2 rounded-full bg-white">
                            <div className="p-2 text-gray-800">
                                <ExternalLinkIcon className="h-6 w-6" />
                            </div>
                        </span>
                    )}

                    {icon && (
                        <div className="m-auto w-3/5 pt-2 pb-8">
                            <div
                                className={classNames(
                                    'flex aspect-[1/1] w-full items-center justify-center rounded-2xl text-black shadow-2xl transition-shadow group-hover:shadow-lg sm:rounded-3xl',
                                    icon.bg
                                )}
                            >
                                <icon.Icon className="w-9/12" colored={true} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </LinkWrapper>
    );
}
